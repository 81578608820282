<template>
	<div id="main">
		<van-row>
			<van-col span="24">
				<van-nav-bar title="服务器状态" left-text="返回" right-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
			</van-col>
		</van-row>
		<van-row>
			<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
				访问著名大型网站，优先使用香港区(百分比越小效果越好)
			</van-notice-bar>
		</van-row>
		<ul v-for="(value, key) in data" :key="key">
			<van-row>
				<van-col span="24">
					<van-divider>{{key}}</van-divider>
				</van-col>
			</van-row>

			<van-row v-for="(value1, key1) in value" :key="key1">
				<van-col span="2">
				</van-col>
				<ul v-for="(value2, key2) in value1" :key="key2">
					<van-col span="7" v-for="(value3, key3) in value2" :key="key3">
						<van-circle v-model="init[value3]" :rate="value3" :speed="100" layer-color="#d0d0d0" color="#64A600" :text="key3" />
					</van-col>
				</ul>
				<van-col span="1">
				</van-col>
			</van-row>
		</ul>
		<van-tabbar v-model="active" @change="onChange">
			<van-tabbar-item icon="search" >查询</van-tabbar-item>
			<van-tabbar-item icon="home-o" url="/Services">列表</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				init: {},
				data: {
					"香港": [
						[{
							"【香港1号】100%": "100"
						}],
					]
				},
				active: 1,
			};
		},
		methods: {
			onChange(index) {
				const routerArray = [
					"/",
					"/Services",
				];
				this.$router.push(routerArray[index])
			},
			onClickLeft() {
				window.history.go(-1);
			},
			onClickRight() {},
		},
		created: function() {
			this.$axios.get("/serverStatus", {
				"id": 123
			}).then(res => {
				//console.log(res.data.data);
				this.data = res.data.data;
			});
		}
	};
</script>

<style>
	.custom-title {
		margin-right: 4px;
		vertical-align: middle;
	}

	#home {
		text-align: center;
	}

	.search-icon {
		font-size: 16px;
		line-height: inherit;
	}
</style>
